<template>
  <div>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="items"
      table-class="table-head-custom table-vertical-center mt-5"
      show-empty
      :busy="isBusy"
    >
      <!-- A custom formatted header cell for field 'name' -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>

      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(name)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder text-h6">
            {{ data.item.name }}
          </span>
        </div>
      </template>

      <template v-slot:cell(employee)="data">
        <CommonEmployeeSummary
          :item="morphEmployee(data)"
        ></CommonEmployeeSummary>
      </template>

      <!-- begin:: Status Cell     -->
      <template v-slot:cell(status)="data">
        <span
          class="label label-lg font-weight-bold label-light-info label-inline"
          >{{ data.item.status }}</span
        >
      </template>
      <!-- end:: Status Cell     -->

      <template #table-busy>
        <AppLoader></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="No Applications"
          text-bottom="..."
        ></AppEmptyList>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length && !isBusy">
      <b-pagination
        size="lg"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="application-pending-page-dt"
        @change="handlePageChange"
      ></b-pagination>
    </div>
    <LeavePageApplicationPendingModal></LeavePageApplicationPendingModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import LeavePageApplicationPendingModal from "@/modules/company/components/leave/application/LeavePageApplicationPendingModal";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";

export default {
  name: "LeavePageApplicationTable",
  props: ["reload"],
  components: {
    CommonEmployeeSummary,
    AppEmptyList,
    LeavePageApplicationPendingModal,
    AppLoader,
  },
  beforeMount() {
    this.fetch();
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "name",
          label: this.$t("LEAVE.TYPE"),
          stickyColumn: true,
          variant: "light",
        },
        {
          key: "employee",
          label: this.$t("EMPLOYEE.HEADER"),
        },
        { key: "from_date", label: this.$t("EDUCATION.FROM") },
        { key: "to_date", label: this.$t("EDUCATION.TO") },
        { key: "unit.requested", label: this.$t("HOLIDAY.DAY") },
        { key: "reason", label: this.$t("ENTITLEMENT.REASON") },
        { key: "status", label: this.$t("JOB.STATUS") },
      ],
      isBusy: false,
    };
  },
  methods: {
    /**
     * Fetch list via store.
     */
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchAllCompanyLeaveApplication")
        .finally(() => (this.isBusy = false));
    },

    /**
     * Handles page change.
     *
     * @param value
     */
    handlePageChange(value) {
      console.log(value);
      this.$store.commit("setAllCompanyLeaveApplicationPage", value);
      this.fetch();
    },

    /**
     * Convert to employee summary format
     *
     * @param row
     * @returns {{code: (string|*), image_url, name: *, email}}
     */
    morphEmployee(row) {
      const employee = row.item.employee;
      console.log(employee);
      return {
        name: `${employee.first_name} ${employee.last_name}`,
        code: employee.unique_code,
        email: employee.email,
        image_url: employee.image_url,
      };
    },

    /**
     * Show pending leave.
     *
     * @param row
     */
    showLeavePending(row) {
      console.log("Selected Leave ", row);
      this.$bvModal.show("leave-page-pending-modal");
      this.$store.dispatch("assignEmployeeId", row.item.id).then(() => {
        console.log("Done setting employee Id");
      });
    },
  },

  computed: {
    ...mapGetters({
      items: "getAllCompanyLeaveApplicationList",
      pagination: "getAllCompanyLeaveApplicationPagination",
      storeBusy: "getAllCompanyLeaveApplicationBusy",
    }),
  },

  watch: {
    reload() {
      console.log("Reload Table!");
      this.$refs["department-list-page-dt"].refresh();
    },

    storeBusy(newBusy) {
      this.isBusy = newBusy;
    },
  },
};
</script>

<style scoped></style>
