<template>
  <div>
    <LeaveApplicationTableControl class="mb-3"></LeaveApplicationTableControl>

    <AppPageCard>
      <template #card-title> {{ $t("LEAVE.APPLICATION_TITLE") }}</template>

      <LeavePageApplicationTable></LeavePageApplicationTable>
    </AppPageCard>
  </div>
</template>

<script>
import LeavePageApplicationTable from "@/modules/company/components/leave/application/LeavePageApplicationTable";
import LeaveApplicationTableControl from "@/modules/company/components/leave/application/table/LeaveApplicationTableControl";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";

export default {
  name: "LeavePageApplication",
  components: {
    AppPageCard,
    LeaveApplicationTableControl,
    LeavePageApplicationTable,
  },
  data() {
    return {
      currentTab: 1,
      countdown: 0,
      busy: false,
      variant: "success",
    };
  },
  methods: {
    getDevMode() {
      let mode = false;
      if (process.env.NODE_ENV === "development") {
        mode = true;
      }

      return mode;
    },
  },
};
</script>

<style scoped></style>
