<template>
  <AppTableControl
    :search-place-holder="$t('COMMON.Actions.Search')"
    @query-debounced="searchName"
    @search-clicked="$store.dispatch('fetchAllCompanyLeaveApplication')"
    no-export
    no-filter
  ></AppTableControl>
</template>

<script>
import AppTableControl from "@/modules/core/components/shared/table/AppTableControl";

export default {
  name: "LeaveApplicationTableControl",
  components: { AppTableControl },
  data() {
    return {
      query: null,
    };
  },
  beforeDestroy() {
    this.$store.dispatch("changeAllCompanyLeaveApplicationBaseFilter", {
      base: null,
    });
  },
  methods: {
    searchName(query) {
      console.log(query);

      this.$store.dispatch("changeAllCompanyLeaveApplicationBaseFilter", {
        base: query,
      });

      this.$store.dispatch("fetchAllCompanyLeaveApplication");
    },
  },

  watch: {
    query(newQuery) {
      console.log(newQuery);
      this.$store.commit("setAllCompanyLeaveApplicationSearch", newQuery);
      this.$store.dispatch("fetchAllCompanyLeaveApplication");
    },
  },
};
</script>

<style scoped></style>
